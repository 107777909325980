import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
// import netflix from 'assets/img/brands/netflix.png';
// import blender from 'assets/img/brands/blender.png';
// import upwork from 'assets/img/brands/upwork.png';
// import facebook from 'assets/img/brands/facebook.png';
// import pocket from 'assets/img/brands/pocket.png';
// import mailBluster from 'assets/img/brands/mail-bluster.png';
// import discord from 'assets/img/brands/discord.png';
// import google from 'assets/img/brands/google.png';

import apazuc from 'assets/img/brands/apazuc.png';
import proto from 'assets/img/brands/amtdc.png';
import iaf from 'assets/img/brands/iaflogobg.png';
import vit from 'assets/img/brands/vitlogo.png';

import isro from 'assets/img/brands/isro.png';
import mod from 'assets/img/brand/mod.png';
import army from 'assets/img/brand/army.png';
import iitm from 'assets/img/brand/iitm.png';

const Brand = ({ image, className }: { image: string; className?: string }) => {
  return (
    <div
      className={classNames(
        className,
        'p-2 p-lg-5 d-flex flex-center h-100 border-1 border-dashed'
      )}
    >
      <img src={image} alt="" className="w-100" />
    </div>
  );
};

const Brands = ({ className }: { className?: string }) => {
  return (
    <section className={className}>
      <div className="container-small px-lg-7 px-xxl-3">
        <Row className="g-0">
          <Col xs={6} md={3}>
            <Brand image={apazuc} className="border-bottom border-end" />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={proto} className="border-bottom border-end-md" />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={iaf}
              className="border-bottom border-end border-end-md"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={vit} className="border-bottom border-end-lg-0" />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={isro}
              className="border-end border-bottom border-bottom-md-0"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand
              image={mod}
              className="border-end-md border-bottom border-bottom-md-0"
            />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={army} className="border-end" />
          </Col>
          <Col xs={6} md={3}>
            <Brand image={iitm} className=" border-end-lg-0" />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Brands;
