import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';
// import ComingSoon from 'pages/ComingSoon';
// import Showcase from 'pages/Showcase';
// import Members from 'pages/Members';
// import EcommerceLayout from 'layouts/EcommerceLayout';
// import AuthCardLayout from 'layouts/AuthCardLayout';
// import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
// import DocPagesLayout from 'layouts/DocPagesLayout';

import Alternate from 'pages/pages/landing/Alternate';
// import Default from 'pages/pages/landing/Default';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Alternate />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
