import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Surveillance Drones',
    horizontalNavLabel: 'Surveillance Drones',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'home',
        icon: 'pie-chart',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Permimeter Security Drones',
            path: '#!',
            pathName: 'default-dashboard',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'Reconnaissance Drones',
            path: '#!',
            pathName: 'project-management-dashbaord',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Wildlife Monitoring Drones',
            path: '#!',
            pathName: 'crm',
            topNavIcon: 'phone',
            active: true
          },
          {
            name: 'Nano Drones',
            path: '#!',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Multi-Spectral Camera Drones',
            path: '#!',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Crop yeild analysis Camera Drones',
            path: '#!',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Volumetric analysis Camera Drones',
            path: '#!',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Real Estate Survey Camera Drones',
            path: '#!',
            pathName: 'social-feed',
            topNavIcon: 'share-2',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'Logistics Drones',
    icon: UilCube,
    pages: [
      {
        name: 'e-commerce',
        active: true,
        icon: 'shopping-cart',
        flat: true,
        pages: [
          {
            name: 'High Payload Capacity Multi-Rotor',
            path: '#!',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Long Range VTOL',
            path: '#!',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'High Altitude Large Capacity',
            path: '#!',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Urban Delivery Drones',
            path: '#!',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          },
          {
            name: 'Emergency Releif Transport Drones',
            path: '#!',
            pathName: 'e-commerce-add-product',
            topNavIcon: 'share-2',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'Inspection Drones',
    icon: UilFilesLandscapesAlt,
    pages: [
      {
        name: 'Oil Pipeline Inspection Drone',
        icon: 'compass',
        path: '#!',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Power Line Inspection Drone',
        icon: 'compass',
        path: '#!',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Marine Infrastructure Inspection Drone',
        icon: 'compass',
        path: '#!',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Railway Track Inspection Drone',
        icon: 'compass',
        path: '#!',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Highway Inspection Drone',
        icon: 'compass',
        path: '#!',
        pathName: 'starter-page',
        active: true
      },
      {
        name: 'Mining / Industrial Site Inspection Drone',
        icon: 'compass',
        path: '#!',
        pathName: 'starter-page',
        active: true
      }
    ]
  },
  {
    label: 'Mapping Drones',
    icon: UilPuzzlePiece,
    active: true,
    pages: [
      {
        name: 'Real Estate Survey Drones',
        icon: 'life-buoy',
        path: '#!',
        pathName: 'getting-started',
        active: true
      },
      {
        name: 'Mining Site Survey Drones',
        icon: 'figma',
        path: '#!',
        pathName: 'doc-design-file',
        active: true
      },
      {
        name: 'Agriculture Land Survey Drones',
        icon: 'git-merge',
        path: '#!',
        pathName: 'changelog',
        active: true
      },
      {
        name: 'Water Body Survey Drones',
        icon: 'fast-forward',
        path: '#!',
        pathName: 'migrations',
        active: true
      },
      {
        name: 'Construction Site Survey Drones',
        icon: 'monitor',
        path: '#!',
        pathName: 'showcase',
        active: true
      }
    ]
  },
  {
    label: 'Agri Drones',
    icon: UilDocumentLayoutRight,
    active: true,
    pages: [
      {
        name: '10l Multi-Copter',
        icon: 'life-buoy',
        path: '#!',
        pathName: 'getting-started',
        active: true
      },

      {
        name: '16l Multi-Copter',
        icon: 'figma',
        path: '#!',
        pathName: 'doc-design-file',
        active: true
      },
      {
        name: '22l Multi-Copter',
        icon: 'git-merge',
        path: '#!',
        pathName: 'changelog',
        active: true
      },
      {
        name: '30l Multi-Copter',
        icon: 'fast-forward',
        path: '#!',
        pathName: 'migrations',
        active: true
      },
      {
        name: '50l Multi-Copter',
        icon: 'monitor',
        path: '#!',
        pathName: 'showcase',
        active: true
      }
    ]
  }
];
